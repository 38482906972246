<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title">Cobranças</h4>
            </div>
          </div>
          <div class="card-body">
            <ApexChart element="order-chart-01" :chartOption="chart1" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title">Previsão de Vendas</h4>
            </div>
          </div>
          <div class="card-body">
            <ApexChart element="yearly-chart-02" :chartOption="chart2" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title">Facturação</h4>
            </div>
          </div>
          <div class="card-body">
            <ApexChart element="yearly-chart-03" :chartOption="chart3" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title">Top Devedores</h4>
            </div>
          </div>
          <div class="card-body">
            <ApexChart element="user-chart-06" class="user-chart-06" :chartOption="chart6" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title">Impostos</h4>
            </div>
          </div>
          <div class="card-body">
            <ApexChart element="user-chart-05" class="user-chart-05" :chartOption="chart5" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="row">
          <div class="col-12">
            <div class="card card-block card-stretch card-height">
              <div class="card-body">
                <div class="rounded m-auto align-items-center">
                  <h4 class="card-title">IVA Trimestral</h4>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <p class="mb-0">1º Trimestre 2022</p>
                    <h4 class="my-2">2.369,91€</h4>
                  </div>
                  <div class="col-md-6">
                    <p class="mb-0">2º Trimestre 2022</p>
                    <h4 class="my-2">7.207,86€</h4>
                  </div>
                  <div class="col-md-6">
                    <p class="mb-0">3º Trimestre 2022</p>
                    <h4 class="my-2">1.737,94€</h4>
                  </div>
                  <div class="col-md-6">
                    <p class="mb-0">4º Trimestre 2022</p>
                    <h4 class="my-2">0,00€</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card card-block card-stretch card-height">
              <div class="card-body">
                <div class="rounded m-auto align-items-center">
                  <h4 class="card-title">Facturação Trimestral</h4>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <p class="mb-0">1º Trimestre 2022</p>
                    <h4 class="my-2">142.665,72€</h4>
                  </div>
                  <div class="col-md-6">
                    <p class="mb-0">2º Trimestre 2022</p>
                    <h4 class="my-2">349.107,84€</h4>
                  </div>
                  <div class="col-md-6">
                    <p class="mb-0">3º Trimestre 2022</p>
                    <h4 class="my-2">194.812,24€</h4>
                  </div>
                  <div class="col-md-6">
                    <p class="mb-0">4º Trimestre 2022</p>
                    <h4 class="my-2">0,00€</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApexChart from "../../../components/charts/ApexChart";
export default {
  name: "Dashbord1",
  components: {
    ApexChart,
  },
  data() {
    return {
      chart1: {
        series: [
          {
            name: "Dentro Prazo",
            data: [0, 0, 0, 0],
          },
          {
            name: "Vencido",
            data: [2912, 2893, 15767, 0],
          },
          {
            name: "Recebido",
            data: [143329, 60441, 81229, 32597],
          },
        ],
        colors: ["#F56692", "#04237D", "#F9756A"],
        chart: {
          type: "bar",
          height: 270,
          stacked: true,
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "top",
                offsetX: -20,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
            borderRadius: 3,
          },
        },
        xaxis: {
          type: "category",
          categories: ["Jun","Jul","Ago","Set"],
          labels: {
            minHeight: 20,
            maxHeight: 20,
          },
        },

        yaxis: {
          show: false,
        },

        legend: {
          markers: {
            radius: 12,
          },
          position: "top",
          horizontalAlign: "left",
          offsetX: -33,
        },
        fill: {
          opacity: 1,
        },

        dataLabels: {
          enabled: false,
        },
      },
      chart2: {
        series: [
          {
            name: "Previsão de Vendas",
            data: [76.545, 82.533, 81.321, 65.243, 44.042, 39.032, 90.412, 132.444, 174.232, 193.123, 134.323, 139.534]
          }
        ],
        colors: ["#F56692", "#ff6b6b"],
        chart: {
          height: 275,
          type: "line",
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        title: {
          text: "",
          align: "left",
        },

        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0,
          },
        },
        xaxis: {
          categories: ["Set","Out","Nov","Dez","Jan","Fev","Mar","Abr","Mai","Jun","Jul","Ago",],
          labels: {
            minHeight: 22,
            maxHeight: 22,
          },
        },
        yaxis: {
          labels: {
            offsetY: 0,
            minWidth: 22,
            maxWidth: 45,

            formatter: function (val) {
              return val + "K";
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: -33,
        }
      },
      chart3: {
        series: [
          {
            name: "2020",
            data: [34.157, 27.527, 5.940, 0, 20.000, 64.740, 0, 25.221, 0, 4.708, 4.045, 29.910],
          },
          {
            name: "2021",
            data: [41.463, 159.780, 0, 0, 6.150, 316.430, 13.557, 55.499, 24.158, 21.216, 8.932, 0],
          },
          {
            name: "2022",
            data: [15.927, 76.963, 49.775, 131.452, 103.748, 113.906, 84.084, 78.041, 32.687, 0, 0, 0],
          },
        ],
        colors: ["#b5b5b3", "#636361","#02f51f"],
        chart: {
          height: 275,
          type: "line",
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        title: {
          text: "",
          align: "left",
        },

        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0,
          },
        },
        xaxis: {
          categories: ["Jan","Fev","Mar","Abr","Mai","Jun","Jul","Ago","Set","Out","Nov","Dez"],
          labels: {
            minHeight: 22,
            maxHeight: 22,
          },
        },
        yaxis: {
          labels: {
            offsetY: 0,
            minWidth: 30,
            maxWidth: 30,

            formatter: function (val) {
              return val + "K";
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: -33,
        },
      },
      chart4: {
        series: [
          {
            name: "Produto 1",
            data: [53, 55, 45, 40, 40, 28, 35, 25, 20, 12, 25, 24],
          },
          {
            name: "Produto 2",
            data: [63, 62, 52, 72, 55, 80, 70, 50, 60, 40, 50, 41],
          }
        ],
        colors: ["#222260", "#F56692"],
        chart: {
          type: "bar",
          height: 305,
          stacked: true,
          zoom: {
            enabled: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: 10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "45%",
            borderRadius: 3,
          },
        },
        xaxis: {
          categories: ["Jan","Fev","Mar","Abr","Mai","Jun","Jul","Ago","Set","Out","Nov","Dez"],
          labels: {
            minHeight: 22,
            maxHeight: 22,
          },
        },
        yaxis: {
          show: false,
        },
        legend: {
          offsetY: 8,
          markers: {
            radius: 12,
          },
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
      },
      chart6: {
        series: [8327, 7547, 1891],
        chart: {
          height: 410,
          type: "donut",
        },
        labels: ["Alex Berger", "Guy Haran", "Huga Hotels, S.A."],
        colors: ["#F56692", "#04237D", "#f57b42", "#f54254", "#51f542"],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          padding: {
            bottom: 0,
          },
        },
        legend: {
          // formatter: function (val) {
          //   return val + " €";
          // },
          position: "bottom",
          offsetY: 8,
          show: true,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                height: 268,
              },
            },
          },
        ],
      },
      chart5: {
        series: [629059.98, 49198.8],
        chart: {
          height: 288,
          type: "donut",
        },
        labels: ["Isento", "IVA23"],
        colors: ["#F56692", "#04237D"],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          padding: {
            bottom: 0,
          },
        },
        legend: {
          formatter: function (val) {
            return val + " €";
          },
          position: "bottom",
          offsetY: 8,
          show: true,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                height: 268,
              },
            },
          },
        ],
      },
    };
  },
};
</script>
