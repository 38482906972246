var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{staticClass:"card card-block card-stretch card-height"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('ApexChart',{attrs:{"element":"order-chart-01","chartOption":_vm.chart1}})],1)])]),_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{staticClass:"card card-block card-stretch card-height"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('ApexChart',{attrs:{"element":"yearly-chart-02","chartOption":_vm.chart2}})],1)])]),_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{staticClass:"card card-block card-stretch card-height"},[_vm._m(2),_c('div',{staticClass:"card-body"},[_c('ApexChart',{attrs:{"element":"yearly-chart-03","chartOption":_vm.chart3}})],1)])]),_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{staticClass:"card"},[_vm._m(3),_c('div',{staticClass:"card-body"},[_c('ApexChart',{staticClass:"user-chart-06",attrs:{"element":"user-chart-06","chartOption":_vm.chart6}})],1)])]),_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{staticClass:"card"},[_vm._m(4),_c('div',{staticClass:"card-body"},[_c('ApexChart',{staticClass:"user-chart-05",attrs:{"element":"user-chart-05","chartOption":_vm.chart5}})],1)])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex justify-content-between"},[_c('div',{staticClass:"header-title"},[_c('h4',{staticClass:"card-title"},[_vm._v("Cobranças")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex justify-content-between"},[_c('div',{staticClass:"header-title"},[_c('h4',{staticClass:"card-title"},[_vm._v("Previsão de Vendas")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex justify-content-between"},[_c('div',{staticClass:"header-title"},[_c('h4',{staticClass:"card-title"},[_vm._v("Facturação")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex justify-content-between"},[_c('div',{staticClass:"header-title"},[_c('h4',{staticClass:"card-title"},[_vm._v("Top Devedores")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex justify-content-between"},[_c('div',{staticClass:"header-title"},[_c('h4',{staticClass:"card-title"},[_vm._v("Impostos")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card-block card-stretch card-height"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"rounded m-auto align-items-center"},[_c('h4',{staticClass:"card-title"},[_vm._v("IVA Trimestral")])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-6"},[_c('p',{staticClass:"mb-0"},[_vm._v("1º Trimestre 2022")]),_c('h4',{staticClass:"my-2"},[_vm._v("2.369,91€")])]),_c('div',{staticClass:"col-md-6"},[_c('p',{staticClass:"mb-0"},[_vm._v("2º Trimestre 2022")]),_c('h4',{staticClass:"my-2"},[_vm._v("7.207,86€")])]),_c('div',{staticClass:"col-md-6"},[_c('p',{staticClass:"mb-0"},[_vm._v("3º Trimestre 2022")]),_c('h4',{staticClass:"my-2"},[_vm._v("1.737,94€")])]),_c('div',{staticClass:"col-md-6"},[_c('p',{staticClass:"mb-0"},[_vm._v("4º Trimestre 2022")]),_c('h4',{staticClass:"my-2"},[_vm._v("0,00€")])])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card-block card-stretch card-height"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"rounded m-auto align-items-center"},[_c('h4',{staticClass:"card-title"},[_vm._v("Facturação Trimestral")])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-6"},[_c('p',{staticClass:"mb-0"},[_vm._v("1º Trimestre 2022")]),_c('h4',{staticClass:"my-2"},[_vm._v("142.665,72€")])]),_c('div',{staticClass:"col-md-6"},[_c('p',{staticClass:"mb-0"},[_vm._v("2º Trimestre 2022")]),_c('h4',{staticClass:"my-2"},[_vm._v("349.107,84€")])]),_c('div',{staticClass:"col-md-6"},[_c('p',{staticClass:"mb-0"},[_vm._v("3º Trimestre 2022")]),_c('h4',{staticClass:"my-2"},[_vm._v("194.812,24€")])]),_c('div',{staticClass:"col-md-6"},[_c('p',{staticClass:"mb-0"},[_vm._v("4º Trimestre 2022")]),_c('h4',{staticClass:"my-2"},[_vm._v("0,00€")])])])])])])])])
}]

export { render, staticRenderFns }